import { graphql, Link } from "gatsby"
import React from "react"
import { withTrans } from "../withTrans"
import Layout from "../components/layout"

export const query = graphql`
  query($locale: [GRAPHCMS_Locale!]!) {
    gcms {
      workWithUsPages(locales: $locale) {
        id
        title
        description
        slug
      }
      vacancyOpenings: openings(
        locales: $locale
        where: { openingType: vacancies }
      ) {
        id
        title
        description
        slug
        openingType
      }
      internshipsOpenings: openings(
        locales: $locale
        where: { openingType: internships }
      ) {
        id
        title
        description
        slug
        openingType
      }
      volunteeringOpenings: openings(
        locales: $locale
        where: { openingType: volunteering }
      ) {
        id
        title
        description
        slug
        openingType
      }
    }
  }
`

class workWithUs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      workWithUsPagesData: this.props.data.gcms.workWithUsPages,
      vacancyOpeningsData: this.props.data.gcms.vacancyOpenings,
      internshipsOpeningsData: this.props.data.gcms.internshipsOpenings,
      volunteeringOpeningsData: this.props.data.gcms.volunteeringOpenings,
    }
  }

  render() {
    const { t } = this.props
    return (
      <Layout>
        <div className="font-futurastd_book text-center lg:grid lg:grid-rows-1 lg:justify-evenly lg:bg-title-dashed-bg lg:bg-no-repeat lg:bg-center mb-3 mx-3 lg:mx-7">
          <div className="text-center inline-block bg-white">
            <span className="text-2xl">&lt;</span>
            <span className="tracking-title-3 uppercase text-base ml-3 mr-2">
              {t("footer_title_work_with_us")}
            </span>
            <span className="text-2xl">&gt;</span>
          </div>
        </div>

        <div className="lg:grid lg:grid-cols-2 mx-7 gap-4">
          <div>
            <div className="my-3 pr-10">
              <p className="font-futurastd_heavy uppercase text-lg lg:bg-title-dashed-bg lg:bg-no-repeat lg:bg-bottom">
                {t("title_vacancies")}
              </p>
              {this.state.vacancyOpeningsData.map((item, i) => (
                <Link key={i}
                  to={item.slug}
                  className="text-xs pb-6 pt-3 font-futurastd_book"
                >
                  {" "}
                  {item.title}
                </Link>
              ))}
            </div>
            <div className="my-3 pr-10">
              <p className="font-futurastd_heavy uppercase text-lg lg:bg-title-dashed-bg lg:bg-no-repeat lg:bg-bottom">
                {t("title_internships")}
              </p>
              {this.state.internshipsOpeningsData.map((item, i) => (
                <Link key={i}
                  to={item.slug}
                  className="text-xs pb-6 pt-3 font-futurastd_book"
                >
                  {" "}
                  {item.title}
                </Link>
              ))}
            </div>
            <div className="my-3 pr-10">
              <p className="font-futurastd_heavy uppercase text-lg lg:bg-title-dashed-bg lg:bg-no-repeat lg:bg-bottom">
                {t("title_volunteering")}
              </p>
              {this.state.volunteeringOpeningsData.map((item, i) => (
                <Link key={i}
                  to={item.slug}
                  className="text-xs pb-6 pt-3 font-futurastd_book"
                >
                  {" "}
                  {item.title}
                </Link>
              ))}
            </div>
          </div>
          <div className="my-3 pr-30">
            <p className="font-futurastd_heavy uppercase text-lg lg:bg-title-dashed-bg lg:bg-no-repeat lg:bg-bottom">
              {this.state.workWithUsPagesData[0].title}
            </p>
            <p className="text-xs pb-6 pt-3 font-futurastd_book">
              {this.state.workWithUsPagesData[0].description}
            </p>
          </div>
        </div>
      </Layout>
    )
  }
}

export default withTrans(workWithUs)
